<template>
    <div
        class="modal fade"
        id="feedbackSentModal"
        tabindex="-1"
        style="z-index: 1000000"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div style="font-weight: 500">
                        {{ t('pages.more.feedback.feedbackSent') }}
                    </div>
                    {{ t('pages.more.feedback.thanks') }}
                </div>
                <div class="border-top">
                    <a
                        @click="router.push('/more')"
                        data-bs-dismiss="modal"
                        href="#"
                        class="w-100"
                        style="font-weight: 600"
                    >
                        <div
                            class="px-2 py-2 text-center tap-react rounded-bottom"
                        >
                            {{ t('misc.modal.done') }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">
                {{ t('pages.more.feedback.feedback') }}
            </h1>
        </div>
        <div class="card shadow-sm p-3 mb-4">
            <p>
                {{ t('pages.more.feedback.explanation') }}
                <router-link
                    to="/more/contact-support"
                    style="font-weight: 500"
                    >{{ t('pages.more.feedback.contactUs') }}</router-link
                >
                {{ t('pages.more.feedback.instead') }}
            </p>
            <p class="m-0">
                {{ t('pages.more.feedback.appreciate') }}
            </p>
        </div>
        <div class="card shadow-sm p-3">
            <div class="mb-3">
                <label for="feedback" class="form-label">{{
                    t('pages.more.feedback.message')
                }}</label>
                <!-- pretter-ignore -->
                <textarea
                    id="feedback"
                    class="form-control w-100"
                    style="height: 15rem"
                    :placeholder="t('pages.more.feedback.type')"
                    v-model="feedbackText"
                    :class="error ? 'is-invalid' : ''"
                ></textarea>
                <div v-if="error" class="invalid-feedback d-block">
                    {{ t('pages.more.feedback.invalid') }}
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">{{
                    t('pages.more.feedback.rate')
                }}</label>
                <div class="d-flex flex-row align-items-center">
                    <i
                        class="fa fa-star gold"
                        :class="[rating > 0 ? 'gold' : 'gray']"
                        @click="rating = 1"
                    ></i>
                    <i
                        class="fa fa-star gold"
                        :class="[rating > 1 ? 'gold' : 'gray']"
                        @click="rating = 2"
                    ></i>
                    <i
                        class="fa fa-star gold"
                        :class="[rating > 2 ? 'gold' : 'gray']"
                        @click="rating = 3"
                    ></i>
                    <i
                        class="fa fa-star gold"
                        :class="[rating > 3 ? 'gold' : 'gray']"
                        @click="rating = 4"
                    ></i>
                    <i
                        class="fa fa-star gold"
                        :class="[rating > 4 ? 'gold' : 'gray']"
                        @click="rating = 5"
                    ></i>
                </div>
            </div>
            <div class="form-check mb-3">
                <input
                    v-model="anonymous"
                    class="form-check-input"
                    type="checkbox"
                    id="anonymous"
                />
                <label class="form-check-label" for="anonymous">
                    {{ t('pages.more.feedback.anonymous') }}
                </label>
            </div>

            <button type="button" @click="sendFeedback" class="btn btn-primary">
                {{ t('pages.more.feedback.send') }}
            </button>
            <a
                href="#"
                data-bs-toggle="modal"
                ref="toggleFeedbackSentModal"
                data-bs-target="#feedbackSentModal"
                class="d-none"
            ></a>
        </div>
    </div>
</template>

<style scoped>
.fa-star {
    padding: 0.3rem;
}
.gold {
    text-shadow: 0 0 1px #e1bf5d;
    font-size: 1.3rem;
    color: var(--bs-primary);
}

.gray {
    text-shadow: 0 0 1.5px #000;
    font-size: 1.3rem;
    color: var(--bs-gray-100);
}
</style>

<script setup>
import { useI18n } from 'vue-i18n';
import { inject, ref } from 'vue';
import BackButton from '@/components/BackButton';
import axios from 'axios';
import { useRouter } from 'vue-router';
const { t } = useI18n();
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const router = useRouter();
const toggleFeedbackSentModal = ref(null);
const feedbackText = ref('');
const rating = ref(0);
const anonymous = ref(false);
const error = ref(false);

async function sendFeedback() {
    if (feedbackText.value == null || feedbackText.value == '') {
        error.value = true;
        return;
    }
    error.value = false;
    await axios.post('feedback', {
        message: feedbackText.value,
        rating: rating.value,
        anonymous: anonymous.value,
    });
    toggleFeedbackSentModal.value.click();
}
</script>

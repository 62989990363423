<template>
    <button @click="router.back()" class="back-btn rounded">
        <i class="fa fa-chevron-left"></i>
    </button>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
</script>

<style scoped>
.back-btn {
    padding: 10px;
    padding-left: 0;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--bs-primary);
    width: 40px;
    height: 40px;
    justify-content: center;
}

.back-btn i {
    font-size: 1.4rem;
}
</style>
